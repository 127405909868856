/* Btn */
button {
	font-size: inherit;
	font-family: inherit;
	outline: none;
	appearance: none;
	border: none;
	background-color: transparent;
	cursor: pointer;
	padding: 0;
}

a {
	text-decoration: none;
}

.btn {
	display: inline-block;
	min-width: 160px;
	font-size: 15px;
	line-height: calc(19.5/15);
	font-weight: 400;
	text-align: center;
	color: $white;
	background-color: $blue-1;
	border-radius: 5px;
	border: $border;
	cursor: pointer;
	padding: 11.5px 24.5px;

	transition: all .2s ease;

	&:not([disabled]):hover, &:focus-visible {
		background-color: $lblue;
	}

	@media (max-width: 576px) {
		min-width: 130px;
	}
	@media (max-width: 480px) {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	/* Btn Views */
	&_small {
		min-width: 100px;
		padding: 8px 24.5px;
	}


	&_main {
		position: relative;
		font-size: 17px;
		line-height: calc(22.1/17);
		font-weight: 500;
		overflow: hidden;
		border: 1px solid $lblue;
		box-shadow: inset -2px -2px 0 $lblue, inset 2px 2px 0 $lblue;
		padding: 10.5px 21.5px;

		&::before, &::after {
			content: attr(data-text);
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 50%;
			left: 0;
			background-color: $lblue;
			overflow: hidden;
			-webkit-transition: -webkit-transform 0.3s;
			transition: transform 0.3s;
			-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
			transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		}
		&::before {
			top: 0;
			padding-top: 10.5px;
		}
		&::after {
			bottom: 0;
			line-height: 0;
		}

		& > span {
			display: block;
			-webkit-transform: scale3d(0.2, 0.2, 1);
			transform: scale3d(0.2, 0.2, 1);
			opacity: 0;
			-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
			transition: transform 0.3s, opacity 0.3s;
			-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
			transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		}

		&:not([disabled]):hover, &:focus-visible {
			background-color: $blue-1;
			box-shadow: none;

			&::before {
				transform: translate3d(0, calc(-100% - 1px), 0);
			}
			&::after {
				transform: translate3d(0, calc(100% + 1px), 0);
			}

			& > span {
				opacity: 1;
				-webkit-transform: scale3d(1, 1, 1);
				transform: scale3d(1, 1, 1);
			}
		}

		@media (max-width: 576px) {
			font-size: 15px;
			line-height: calc(19.5/15);
			padding: 12px 13.5px;

			&::before {
				padding-top: 12px;
			}
			&::after {
				padding-bottom: 1px;
			}
		}
	}

	&_hlight {
		color: $white;
		background-color: $lblue;

		&:not([disabled]):hover, &:focus-visible {
			background-color: lighten($lblue, 2);
		}
		&:not([disabled]):active {
			background-color: $lblue;
		}
	}

	&_tab {
		color: $grey;
		border: 1px solid transparent;
		padding: 10.5px 21.5px;
		&.active {
			color: $white;
			border-color: $lblue;
		}
		
		&:not([disabled]):hover, &:focus-visible {
			background-color: $blue-1;
			color: $white;
		}

		@media (max-width: 576px) {
			padding: 10.5px 11px;
		}
	}

	&_plain {
		min-width: auto;
		font-size: 13px;
		line-height: calc(16.9/13);
		color: $white;
		border: $border;
		padding: 13px 12px;
		
		&:not([disabled]):active {
			background-color: $blue-1;
			border-color: $lblue;
		}
		&:not([disabled]):hover, &:focus-visible {
			background-color: $blue-1;
		}
	}

	&_stroke {
		color: $white;
		border: 1px solid $lblue;
		transition: border-color s2s ease;

		&:hover, &:focus-visible {
			background-color: $blue-1!important;
			border-color: $lgrey;
		}
		&:active {
			border-color: $grey;
		}
	}

	&_inline {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 7px;
		color: $white;

		transition: color .2s ease;

		&:not([disabled]) {
			&:hover, &:focus-visible {
				color: $lblue;
	
				.btn__icon {
					&.icon_rotate {
						transform: rotate(-360deg);
					}
					
					&.icon_bounce {
						animation: bounce 0.1s;
            animation-direction: alternate;
            animation-timing-function: cubic-bezier(.5, 0.8, 1, .5);
            animation-iteration-count: 2;
						animation-delay: 0.1s;
					}
					&.icon_pulse {
						animation-name: pulse;
						animation-duration: 500ms;
						animation-timing-function: ease;
						animation-iteration-count: 1;
					}
				}
			}
		}
		

		&[disabled] {
			color: $grey;

			.btn__icon {
				opacity: .5;
			}
		}

		@media (max-width: 480px) {
			font-size: 15px;
			line-height: calc(19.5/15);

			.btn__icon {
				width: 12px;
				height: 12px;
			}
		}
	}

	&__icon {
		transition: transform .35s ease-in-out;
	}


	&_table-sort {
		display: flex;
		align-items: center;
		column-gap: 5px;
		color: inherit;

		&::after {
			content: url('../img/icon-sort.svg');
			height: 14px;
		}

		&:focus-visible {
			color: $lgrey;
		}
	}

	&_up {
		position: absolute;
		left: calc(100% + 30px);
		bottom: 0;
		z-index: 300;

		&:focus-visible {
			outline: $outline;
		}

		@media (max-width: 1520px) {
			left: auto;
			bottom: auto;
			top: calc(100% + 10px);
			right: 0;
		}
	}

	/* Btn states */
	&[disabled] {
		background-color: $blue-1;
		color: $grey;
	}

	&:not([disabled]).highlighted {
		color: $white;
		background-color: $lblue;

		&:focus-visible {
			outline: -webkit-focus-ring-color auto 1px;	
		}
	}
}

a.disabled {
	color: $grey;
	pointer-events: none;
}

.btns {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	column-gap: 15px;
	row-gap: 10px;
	
	.btn {
		flex: 1;
	}
}
/* Btn end */

/* Input Text */
input[type="text"] {
	appearance: none;
	outline: none;
	box-shadow: none;

	width: 100%;
	font-size: 15px;
	line-height: calc(19.5/15);
	color: $white;
	background-color: transparent;
	border: 1px solid $grey;
	border-radius: 5px;
	padding: 11.5px 15px;

	transition: all .2s ease;
	&:focus {
		border-color: $white;
		outline: none;
	}

	&::placeholder {
		color: $grey;
	}

	/* Input text states */
	
	&.error {
		border-color: $red;
		// padding-right: 136px;

		& + .msg {
			display: block;
			width: 116px;
			text-align: right;
			color: $red;

			@media (max-width: 480px) {
				width: 100px;
			}
		}

		@media (max-width: 480px) {
			// padding-right: 110px;
		}
	}

	&[disabled] {
		cursor: not-allowed;
	}
}

.input-max-btn {
	font-size: 13px;
	line-height: calc(16.9/13);
	color: $grey;
	background-color: $blue-1;
	border: $border;
	border-radius: 3px;
	padding: 4px 12px;

	transition: all .2s ease;
	&:not([disabled]):active {
		background-color: $blue-1;
		border-color: $lblue;
	}
	&:not([disabled]):hover, &:focus-visible {
		color: $lgrey;
	}
}

.modal input[type="text"] {
	appearance: none;
	outline: none;
	box-shadow: none;

	width: 100%;
	font-size: 15px;
	line-height: calc(19.5/15);
	color: $white;
	background-color: transparent;
	border: 1px solid $grey;
	border-radius: 5px;
	padding: 11.5px 15px;

	transition: all .2s ease;
	&:focus {
		border-color: $white;
		outline: none;
	}

	&::placeholder {
		color: $grey;
	}

	/* Input text states */
	
	&.error {
		border-color: $red;
		// padding-right: 136px;

		& + .msg {
			display: block;
			width: 116px;
			text-align: right;
			color: $red;

			@media (max-width: 480px) {
				width: 100px;
			}
		}

		@media (max-width: 480px) {
			// padding-right: 110px;
		}
	}

	&[disabled] {
		cursor: not-allowed;
	}
}
/* Input Text end */

/* Modal */
.modal {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(3, 10, 33, 0.85);
	visibility: hidden;
	transition: opacity .3s ease-out;
	opacity: 0;
	overflow: auto;
	
	&.active {
		visibility: visible;
		opacity: 1;
	}

	&__content {
		width: 435px;
		background-color: $blue-2;
		border-radius: 5px;
		border: 1px solid rgba(56, 66, 99, 0.2);
		padding: 25px 35px;

		transform-style: preserve-3d;
		transform: rotateX(-60deg);
		transform-origin: 50% 0;
		opacity: 0;
		transition: all .3s ease;

		&-wrapper {
			position: relative;
			-webkit-perspective: 1300px;
			perspective: 1300px;
		}

		&-wrapper {
			@media (max-width: 480px) {
				width: 100%;
				margin: 0 auto;
			}
		}
	}

	&__title {
		margin-bottom: 15px;
	}
	
	&__btn {
		width: 100%;
		margin-top: 10px;
	}

	&.active &__content {
		opacity: 1;
		transform: rotateX(0);
	}
	&.active .close-btn {
		opacity: 1;
	}

	.close-btn {
		position: absolute;
		z-index: 30;
    right: 20vw;
    top: 10vh;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 11px;
		color: $lblue;
		opacity: 0;
		transition: opacity .3s ease;

		&::after {
			content: '';
			display: block;
			flex-shrink: 0;
			width: 40px;
			height: 40px;
			background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22none%22%3E%0A%3Cg%20clip-path%3D%22url%28%23clip0_712_212%29%22%3E%0A%3Cpath%20d%3D%22M40%203.5L36.5%200L20%2016.5L3.5%200L0%203.5L16.5%2020L0%2036.5L3.5%2040L20%2023.5L36.5%2040L40%2036.5L23.5%2020L40%203.5Z%22%20fill%3D%22%236988FF%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22clip0_712_212%22%3E%0A%3Crect%20width%3D%2240%22%20height%3D%2240%22%20fill%3D%22white%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E');
			background-repeat: no-repeat;

			@media (max-width: 768px) {
				width: 25px;
				height: 25px;
				background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2025%2025%22%20fill%3D%22none%22%3E%0A%3Cpath%20d%3D%22M25%202.1875L22.8125%200L12.5%2010.3125L2.1875%200L0%202.1875L10.3125%2012.5L0%2022.8125L2.1875%2025L12.5%2014.6875L22.8125%2025L25%2022.8125L14.6875%2012.5L25%202.1875Z%22%20fill%3D%22%236988FF%22%2F%3E%0A%3C%2Fsvg%3E');
			}
		}

		@media (max-width: 1300px) {
			bottom: calc(100% + 30px);
		}

		@media (max-width: 768px) {
			bottom: calc(100% + 20px);
			right: 50px;
		}

		@media (max-width: 480px) {
			right: 20px;
		}
	}

	@media (max-width: 576px) {
		&__content {
			padding: 20px 25px;
			margin: 0 20px;
		}

		&__title {
			font-size: 25px;
			line-height: calc(32.5/25);
			margin-bottom: 20px;
		}
	}

	@media (max-width: 480px) {
		&__content {
			width: 100%;
			padding: 20px;
			margin: 0;
		}
	}

	/* Modal content style */

	&__info-field {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 50px;
		margin-bottom: 10px;

		&-title {
			max-width: 105px;
			color: $grey;
		}

		&-val {
			font-size: 20px;
			line-height: calc(26/20);
			text-align: right;

			&.highlighted {
				color: $lblue;
			}

			.val_minor {
				display: inline-block;
				flex-shrink: 0;
				font-size: 15px;
				line-height: 0;
				color: $grey;
			}

			&_complex {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
				align-items: center;
				column-gap: 10px;
				row-gap: 5px;

				@media (max-width: 480px) {
					flex-direction: column;

					.val_minor {
						line-height: calc(19.5/15);
					}

					.icon_arrow {
						position: absolute;
						left: -20px;
						top: 50%;
						transform: rotate(90deg) translateY(-50%);
					}
				}
			}
		}

		&_hl {
			border-top: 1px solid $blue-1;
			padding-top: 18px;
		}
		&_hl &-title {
			color: $white;
		}
	}

	&__form-wrapper .form-field {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-top: 15px;

		.btn, .input-container {
			width: 100%;
		}

		.input-container {
			display: flex;
			gap: 10px;

			.btn_plain {
				width: fit-content;
			}
		}
	}

	&__tip {
		font-size: 14px;
		line-height: calc(18.2/14);
		font-weight: 500;
		color: $lgrey;
		margin-top: 15px;
	}

	&__text {
		color: $lgrey;
	}

	@media (max-width: 576px) {
		&__steps {
			gap: 24px;
		}
		&__step {
			font-size: 13px;
			line-height: calc(16.9/13);
		}

		&__info-field {
			&-val {
				font-size: 15px;
				line-height: calc(19.5/15);
			}
		}
	}

	&.modal_register &__content, &.modal_warning &__content {
		width: 665px;

		@media (max-width: 768px) {
			width: 400px;
		}

		@media (max-width: 480px) {
			width: calc(100% - 40px);
			margin: 0 auto;
		}
	}
	&.modal_register &__title {
		margin-bottom: 15px;
	}

	&.modal_register {
		.text-cols {
			display: flex;
			flex-wrap: wrap;
			flex-wrap: wrap;
			// column-gap: 30px;
			column-gap: 25px;
			row-gap: 10px;
			// justify-content: space-between;
			margin-bottom: 15px;

			@media (max-width: 380px) {
				// column-gap: 0;
			}
		}
		.text-col {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			// width: 120px;

			@media (max-width: 380px) {
				// width: 105px;
				font-size: 15px;
				line-height: calc(19.5/15);
			}
			
			p {
				margin: 0;
			}

			.icon-row {
				display: flex;
				align-items: center;
				column-gap: 10px;
			}
		}

		.btn {
			width: 100%;
		}
		.submit-btn {
			margin-bottom: 15px;
			
			@media (max-width: 480px) {
				margin-bottom: 10px;
			}
		}

		.form-field {
			row-gap: 20px;
			
			@media (max-width: 480px) {
				row-gap: 15px;
			}
		}
	}

	&.modal_warning {
		.modal {
			&__title {
				margin-bottom: 10px;
			}

			&__checkbox {
				margin-top: 16px;

				input:checked + label {
					color: #fff;
				}
				label {
					color: #384263;
					transition: color .2s ease;
				}
			}
		}

		p {
			margin: 10px 0;
		}
		
		button {
			width: 100%;
			margin-top: 20px;
		}
	}

	&.modal_SLTP-Options &__content {
		padding: 25px 30px;
	}
}
/* Modal end */

/* Logo */
.logo {
	display: inline-block;
	font-family: "Electrolize", sans-serif;
	font-size: 25px;
	line-height: calc(32.5/25);
	font-weight: 400;
	text-decoration: none;
	color: $white;

	@media (max-width: 576px) {
		font-size: 17px;
		line-height: calc(22.1/17);
	}
}
/* Logo end */

/* Radio and checkbox */
.input-container {
	input[type="radio"], input[type="checkbox"] {
		cursor: pointer;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		appearance: none;

		& + label {
			display: flex;
			align-items: flex-start;

			&::before, &::after {
				position: relative;
				top: 1px;
			}

			&::before {
				content: '';
				display: block;
				width: 15px;
				height: 15px;
				background-color: $white;
				border-radius: 50%;
				margin-right: 13px;
			}
			&::after {
				content: '';
				display: block;
				width: 9px;
				height: 9px;
				position: absolute;
				left: 3px;
				top: 6px;

				background-color: $lblue;
				border-radius: 50%;
				transform: scale(0);
				transform-origin: center;
				transition: transform .15s ease-in;
			}
		}

		&:checked + label {
			&::after {
				transform: scale(1);
			}
		}
	}
}

.input-container {
	input[type="checkbox"] {
		padding: 0;
		
		& + label {
			position: relative;
			
			&::before {
				flex-shrink: 0;
				width: 20px;
				height: 20px;
				background-color: #202946;
				border-radius: 3px;
				margin-right: 10px;
			}

			&::after {
				content: '';
				left: 5px;
				top: 7px;
				display: block;
				flex-shrink: 0;
				width: 10px;
				height: 7px;
				background-color: transparent;
				background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%227%22%20viewBox%3D%220%200%2010%207%22%20fill%3D%22none%22%3E%0A%3Cpath%20d%3D%22M8.44558%200.223176C8.61838%200.0784483%208.84834%20-0.00156301%209.08693%202.51043e-05C9.32551%200.00161322%209.55407%200.0846768%209.72436%200.231688C9.89466%200.3787%209.99337%200.57816%209.99968%200.787982C10.006%200.997804%209.91939%201.20157%209.75816%201.3563L4.86357%206.74132C4.7794%206.82107%204.67782%206.88507%204.5649%206.92949C4.45198%206.97391%204.33003%206.99785%204.20636%206.99986C4.08268%207.00188%203.95981%206.98193%203.8451%206.94122C3.73038%206.9005%203.62618%206.83986%203.53872%206.7629L0.292827%203.90744C0.202435%203.83334%200.129933%203.74399%200.0796475%203.6447C0.0293621%203.54542%200.00232279%203.43825%200.000143182%203.32957C-0.00203643%203.2209%200.0206883%203.11295%200.0669613%203.01217C0.113234%202.91139%200.182108%202.81984%200.269473%202.74298C0.356838%202.66612%200.460905%202.60553%200.575465%202.56483C0.690026%202.52412%200.812734%202.50413%200.936267%202.50605C1.0598%202.50796%201.18163%202.53175%201.29449%202.57599C1.40734%202.62022%201.50892%202.68401%201.59315%202.76353L4.16189%205.02221L8.42227%200.246918C8.42994%200.238609%208.4369%200.230683%208.44558%200.223176Z%22%20fill%3D%22%236988FF%22%2F%3E%0A%3C%2Fsvg%3E');
				background-repeat: no-repeat;
				background-position: center;
				transform: translateY(15px);
				opacity: 0;
				transition: transform .2s ease, opacity .2s ease;
			}
		}

		&:checked + label {
			&::before {
				background-color: $grey;
			}
			&::after {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}
/* Radio and checkbox end */

button[disabled], .input-container input[disabled] {
	cursor: not-allowed;
}


/* Loader */
.loader-container {
  width: 50px;
	height: 50px;
	margin: 20px auto;
}

@keyframes spin {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

.loader {
  transform-origin: center;
  animation-name: spin;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier;
  animation-iteration-count: infinite;

	@media (max-width: 480px) {
		&-container {
			width: 30px;
			height: 30px;
		}

		stroke-width: 5px;
	}
	
}
/* Loader end */

/* Warning */
.warning {
	position: fixed;
	z-index: 900;
	left: 0;
	right: 0;
	bottom: 0;
	font-size: 15px;
	line-height: 1.3;
	font-weight: 400;
	background-color: $blue-2;
	text-align: right;
	white-space: nowrap;
	cursor: default;
	padding: 10px 20px;

	@media (max-width: 576px) {
		text-align: center;
	}
}
/* Warning end */


/* Tip */
.Tip {
	font-size: 13px;
	line-height: calc(16.9/13);
	color: $lgrey;

	&_bordered {
		background-color: $blue-17;
		border: $border;
		border-radius: 5px;
		padding: 11px 15px;
	}
}
/* Tip end */